/* Skills */
.skills {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.row {
    display: flex;
    justify-content: space-between;
}

.skill p {
    cursor: default;
    font-size: 1.2rem;
    font-family: var(--footer-font);
}

.skill-icon {
    color: gray;
    font-size: 2.5rem;
}

.skill-icon:hover {
    color: var(--orange);
}

/* Socials */
.socials {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-left: 3rem;
}

@media only screen and (max-width: 768px) {

    /* Mobile-specific styles */
    .socials {
        margin-left: 4rem;
    }

    #open,
    #closed {
        max-width: 300px;
    }
}

.socials .row {
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.socials-icon {
    font-size: 2rem;
    color: grey;
    margin-right: 2rem;
    transition: ease 0.3s;
}

.socials p {
    cursor: default;
    font-size: 1.2rem;
    font-family: var(--footer-font);
    transition: ease 0.3s;
}

.socials .row:hover .socials-icon {
    transform: scale(1.1);
    color: var(--purple);
}

#row-github {
    border-top: dashed var(--purple);
    border-bottom: dashed var(--purple);
    width: 80%;
}

/* Contact */
.contact {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.contact .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: default;
    margin-top: 0.5rem;
}

.contact-icon {
    font-size: 1.5rem;
    color: grey;
    margin-right: 2rem;
    transition: ease 0.3s;
}

.contact .row:hover .contact-icon {
    transform: scale(1.1);
    color: var(--pink);
}

.contact p {
    cursor: default;
    font-size: 1rem;
    font-family: var(--footer-font);
    transition: ease 0.3s;
    margin-top: 0;
    margin-bottom: 0;
}

#seperator {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.line {
    height: 3px;
    background-color: var(--pink);
    width: 35%;
}

#or {
    font-family: var(--section-header-font);
    font-weight: 600;
    font-size: 1.5rem;
}

.message-box {
    margin-top: 1rem;
}

.message-box p {
    font-size: 1.5rem;
    font-weight: 400;
}

.message-box textarea {
    resize: none;
    width: 100%;
    font-family: var(--footer-font);
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 0.3rem;
    transition: 0.4s ease;
}

.message-box .row textarea:focus {
    outline: none;
    background-color: rgb(249, 132, 200, 0.2);
    border: solid 1px rgb(249, 132, 200, 0.05);
    border-radius: 0;
}

.message-box .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8rem;

}

.message-box .row label {
    font-family: var(--footer-font);
    font-size: 1.2rem;
}

.message-box .row input {
    font-size: 1rem;
    font-family: var(--footer-font);
    border-top: none;
    border-bottom: solid 1px black;
    border-left: none;
    border-right: none;
    width: 11.9rem;
    transition: 0.4s ease;
}

.message-box .row input:focus {
    outline: none;
    border-bottom: solid 1.5px var(--pink);
    background-color: var(--pink);
}

#border-button {
    font-family: var(--footer-font);
    font-size: 1.3rem;
    margin-right: 2rem;
    padding-top: 0.48rem;
    padding-bottom: 0.6rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0rem;
    outline: none;
    border: solid black 1px;
    background-color: transparent;
    transition: 0.2s;
    z-index: 1;
    position: relative;
    cursor: pointer;
}

#border-button:hover {
    transform: translate(12.5px, 14.5px);
    /* Move right and down on hover */
    border: solid transparent 1px;
}

#border-button:active {
    border: solid transparent 1px;
    color: white;
}

#border-button:active+#send-message-button {
    background-color: rgba(207, 119, 169, 0.8);
}

.button-shadow {
    cursor: pointer;
    outline: none;
    border: none;
    height: 2.8rem;
    margin-left: 0rem;
    margin-right: 0.5rem;
    transition: 0.3s;
    max-width: 390px;
    margin-top: -35px;
    width: 173px;
    position: relative;
    z-index: -1;
}

#send-message-button {
    background-color: var(--pink);
}

/* Experience */
.experience {
    margin-top: 2rem;
}

.job {
    font-family: var(--footer-font);
    text-align: left;
    border-bottom: dashed var(--yellow);
    margin-bottom: 1rem;
    margin-left: 1rem;
    transition: ease 0.3s;
}

.job:hover {
    transform: scale(1.1);
}

.job .row {
    align-items: center;
    justify-content: flex-start;
}

.job-icon {
    color: var(--yellow);
    font-size: 2rem;
}

.job .row p {
    margin-bottom: 0rem;
    margin-top: 0rem;
    font-family: var(--section-header-font);
    font-size: 1.5rem;
    letter-spacing: 1px;
}

.job-inner {
    margin-left: 2rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.job-inner p {
    margin-top: 0;
    margin-bottom: 0;
    color: rgb(75, 74, 74);
}

.job-dates {
    font-size: 0.9rem;
    font-style: italic;
}

.job-descript {
    text-wrap: wrap;
    max-width: 390px;
}

.volunteering {
    font-family: var(--section-header-font);
    padding-bottom: 1rem;
    font-size: 1.8rem;
    margin-top: 0;
    max-width: 390px;
}

/* Projects */
.projects {
    margin-top: 1rem;
}

.project {
    padding-bottom: 1rem;
    padding-top: 1rem;
}

#equpment-project {
    border-bottom: solid 3px #C5FF95;
}

#eventhub-project {
    border-bottom: solid 3px rgb(219, 162, 148);
}

#pegasus-project {
    border-bottom: solid 3px rgb(121, 179, 197);
}

#smishing-project {
    border-bottom: solid 3px rgb(238, 217, 32);
}

.screenshot {
    background-image: url(./images/eventhub.jpg);
    background-size: cover;
    width: 100%;
    height: 110px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin-bottom: 1rem;
}

.title {
    font-family: var(--section-header-font);
    font-size: 1.8rem;
    letter-spacing: 1px;
    font-weight: 500;
    margin-top: 0;
    cursor: pointer !important;
}

#equpment{
    font-family:  "Moderustic", sans-serif;
    font-weight: 600;
    padding-bottom: 0.2rem;
    margin: 0;
    background-color:#C5FF95;
    color: #074173;
    text-shadow: 2px 2px 2px #5debd7;
}

#equpment-bottom-title{
    font-family:  "Moderustic", sans-serif;
    font-weight: 600;
    margin-bottom: 1rem;
    padding-bottom: 0.2rem;
    margin-top: 0;
    background-color:#C5FF95;
    color: #074173;
    text-shadow: 2px 2px 2px #5debd7;
}

#eventhub {
    font-family: "Protest Riot", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    background-color: rgb(219, 162, 148);
}

#pegasus {
    background-color: rgb(121, 179, 197);
    color: white;
    padding-top: 0;
    font-family: "Big Shoulders Stencil Text", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    letter-spacing: 2px;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

.proj-bottom {
    display: flex;
    max-width: 390px;
}

.description {
    text-align: left;
}

.buttons {
    display: flex;
    flex-direction: column;
}

#read-more-button {
    background-color: rgba(255, 105, 97, 0.3);
    width: 105px;
    height: 2.5rem;
    margin-left: 0.8rem;
}

#code-button {
    background-color: rgba(255, 105, 97, 0.5);
    width: 90px;
    height: 2.5rem;
    margin-left: 0.8rem;
}

#live-button {
    background-color: rgba(255, 105, 97, 0.7);
    width: 83px;
    height: 2.5rem;
    margin-left: 0.8rem;
}

.project-button {
    text-align: left;
}

.project-button #border-button {
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    font-size: 1rem;
}

.project-button #border-button:hover {
    border: solid 1px rgba(255, 105, 97, 0);
}

.proj-icon {
    margin-right: 0.2rem;
    font-weight: 100;
}

#pegasus-screenshot {
    background-image: url(./images/pegasus.jpg);
    background-size: cover;
}

#pegasus-buttons #read-more-button {
    background-color: rgba(121, 179, 197, 0.3);
}

#pegasus-buttons #code-button {
    background-color: rgba(121, 179, 197, 0.5);
}

#pegasus-buttons #live-button {
    background-color: rgba(121, 179, 197, 0.7);
}

#smishing {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.3rem;
    font-family: "Roboto Slab", serif;
    background-color: rgb(136, 174, 227, 0.7);
    color: rgb(22, 50, 88);
    text-shadow: 1px 1px 2px rgb(238, 217, 32);
}

#smishing-bottom-title {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-family: "Roboto Slab", serif;
    background-color: rgb(136, 174, 227, 0.7);
    color: rgb(22, 50, 88);
    text-shadow: 1px 1px 2px rgb(238, 217, 32);
    padding-bottom: 0.5rem;
}

#smishing-buttons #read-more-button {
    background-color: rgb(238, 217, 32, 0.3);
}

#smishing-buttons #code-button {
    background-color: rgb(238, 217, 32, 0.5);
}

#smishing-buttons #live-button {
    background-color: rgb(238, 217, 32, 0.7);
}

#smishing-screenshot {
    background-image: url(./images/how.jpg);
    background-size: cover;
}

.description {
    margin-right: 1rem;
    font-family: var(--footer-font);
    font-size: 1rem;
}

#equpment-buttons #read-more-button {
    background-color: rgba(197, 255, 149, 0.3);
}

#equpment-buttons #code-button {
    background-color: rgba(197, 255, 149, 0.5);
}

#equpment-buttons #live-button {
    background-color: rgba(197, 255, 149, 0.7);
}

/* education */
.education {
    margin-top: 2rem;
}

#education-icon {
    color: var(--green);
}

#school {
    border-bottom: dashed var(--green);
}

/*about*/
.about {
    font-family: var(--footer-font);
    text-align: left;
    margin-top: 2rem;
    max-width: 380px;
}