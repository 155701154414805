.hero {
    width: 99vw;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    position: relative;
}

.hero-padding {
    background-color: #e6f3ff;
    position: sticky;
    width: 99vw;
}

.hero-inner {
    min-width: 35vw;
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}

.hero p {
    text-align: left;
    padding-right: 3rem;
}

#hero-top {
    font-size: 3rem;
    margin-top: 5rem;
    margin-bottom: 0rem;
    font-family: "Fjalla One", sans-serif;
    font-weight: 400;
    letter-spacing: 1px;
    text-shadow: 1px 1px 2px var(--yellow);
    color: rgba(0, 0, 0, 0.8);
}

#hero-main {
    font-size: 2rem;
    margin-bottom: 2.5rem;
    font-family: var(--footer-font);
    margin-top: 0rem;
}

@media only screen and (max-width: 768px) {

    /* Mobile-specific styles */

    .hero-inner {
        min-width: 35vw;
        align-self: center;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }

    .hero p {
        text-align: center;
        width: 100vw;
        padding-right: 0 !important;
    }

    #hero-top {
        margin-top: 1rem !important;
        font-size: 3rem;
    }

    #hero-main {
        margin-bottom: 1rem !important;
        font-size: 1.5rem;
        text-align: center !important;
    }
}