:root {
  --red: rgb(255, 105, 97, 0.8);
  --orange: rgb(246, 149, 82, 0.8);
  --yellow: rgb(255, 211, 71, 0.8);
  --green: rgb(178, 204, 0, 0.8);
  --blue: rgb(90, 122, 211, 0.65);
  --purple: rgb(150, 111, 214, 0.65);
  --pink: rgb(249, 132, 200, 0.8);
  --section-header-font: "Playfair Display", serif;
  --footer-font: "Barlow", sans-serif;
}


html {
  height: 99vh;
}

body {
  height: 99vh;
  display: flex;
  justify-content: center;
  background-color: white;
  margin: 0;
}

.App {
  max-width: 99vw;
  min-height: 98vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {

  /* Mobile-specific styles */
  .App {
    width: 100vw;
    align-items: center !important;
  }

  .main {
    position: sticky;
    z-index: 1;
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100vw !important;
  }
}

.main {
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: space-between;
  margin-top: 2rem;
}