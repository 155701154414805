.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 50;
}

.popup-inner {
    position: relative;
    margin-bottom: 5%;
    padding-right: 3%;
    padding-left: 3%;
    padding-top: 2%;
    padding-bottom: 3%;
    min-width: 40vw;
    max-width: 95vw;
    background-color: white;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 0.5rem;
}

@media only screen and (max-width: 768px) {

    /* Mobile-specific styles */
    .popup-inner {
        padding-right: 4%;
        padding-left: 4%;
        padding-top: 3%;
        padding-bottom: 6% !important;
    }
}

#close-popup {
    color: var(--pink);
    font-size: 2vw;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

#close-popup-icon {
    transition: 0.2s;
    cursor: pointer;
    font-size: 2.5rem;
}

#close-popup-icon:hover {
    transform: scale(1.2);
}

.popup-inner p {
    margin-top: 0.5rem;
}